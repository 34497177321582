import axios from 'axios'
import qs from 'qs'
import store from '@/store'
// eslint-disable-next-line
import router from '@/router'
import storage from 'store'
import notification from 'ant-design-vue/es/notification'
import {
  VueAxios
} from './axios'
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN
} from '@/store/mutation-types'

// axios retry
axios.defaults.retry = 4
axios.defaults.retryDelay = 1000

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 200000 // 请求超时时间
})

// 异常拦截处理器
const errorHandler = error => {
  if (error.code === 'ECONNABORTED') {
    notification.error({
      message: '服务器错误',
      description: '请求超时'
    })
  }
  if (error.message.includes(404)) {
    notification.error({
      message: '404 Not Found',
      description: `${error.config.url}`
    })
  }
  if (error.response) {
    const data = error.response.data
    if (error.response.status === 500) {
      if (data.msg.search('refresh') === -1) {
        notification.error({
          message: '错误',
          description: data.msg === 'The user does not exist' ? '账号不存在' : data.msg
        })
      }
    } else if (error.response.status === 403 || error.response.status === 401) {
      notification.error({
        message: '错误',
        description: data.msg === 'verification code failed to send, please try again！' ? '验证码发送失败,请重试' : data.msg
      })
    }
  }
  return Promise.reject(error.response ? error.response.data : error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  config.headers['Call_Source'] = 'health_admin'
  if (token) {
    if (!config.url.includes('verificationCode')) {
      config.headers['Authorization'] = token
    }
  }
  if (config.method === 'get') {
    // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
    config.paramsSerializer = function (params) {
      return qs.stringify(params, {
        arrayFormat: 'repeat'
      })
    }
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use(
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 0) {
      // 401: Token expired;
      if (res.code === 401) {
        // to re-login
        const refresh_token = storage.get(REFRESH_TOKEN)
        const {
          fullpath
        } = router
        storage.remove(ACCESS_TOKEN)
        store
          .dispatch('refreshToken', {
            refresh_token
          })
          .then(() => {
            location.reload()
          })
          .catch(() => {
            notification.error({
              message: '提示',
              description: '您已退出登录，若非本人操作，请及时联系机构负责人！'
            })
            store.commit('SET_TOKEN', '')
            store.commit('SET_ROLES', [])
            storage.remove(REFRESH_TOKEN)
            router.push({
              path: storage.get('loginPath'),
              params: {
                redirect: fullpath
              }
            })
          })
        // const { fullpath } = router
        // notification.error({
        //   message: '提示',
        //   description: '登录信息已失效'
        // })
        // store.commit('SET_TOKEN', '')
        // store.commit('SET_ROLES', [])
        // storage.remove(ACCESS_TOKEN)
        // storage.remove(REFRESH_TOKEN)
        // router.push({ path: '/user/login', params: { redirect: fullpath } })
      } else {
        return res
      }
    } else {
      return res
    }
  },
  // errorHandler
  error => {
    if (error.code !== 'ECONNABORTED') {
      return errorHandler(error)
    }
    var config = error.config
    // If config does not exist or the retry option is not set, reject
    if (!config || !config.retry) {
      return errorHandler(error)
    }

    // Set the variable for keeping track of the retry count
    config.__retryCount = config.__retryCount || 0

    // Check if we've maxed out the total number of retries
    if (config.__retryCount >= config.retry) {
      // Reject with the error
      return errorHandler(error)
    }

    // Increase the retry count
    config.__retryCount += 1

    // Create new promise to handle exponential backoff
    var backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve()
      }, config.retryDelay || 1)
    })

    // Return the promise in which recalls axios to retry the request
    return backoff.then(function () {
      config.url = config.url.replace(config.baseURL + '/', '/')

      return request(config)
    })
  }
)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios, request as axios
}