<template>
  <a-cascader :value="value" @change="e => $emit('input', e)" v-bind="$attrs" v-on="$listeners">
    <a-icon slot="suffixIcon" type="caret-down" />
    <slot></slot>
  </a-cascader>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style lang="less" scoped></style>
