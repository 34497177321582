import request from '@/utils/request'
const userApi = {
  list: '/user',
  list4Student: '/user/student',
  create: '/user',
  update: id => `/user/${id}`,
  detail: id => `/user/${id}`,
  remove: id => `/user/${id}`,
  getInfo: '/user/getUserInfo',
  enable: '/user/enable'
}
// 员工账号、启用禁用
export function enable(parameter) {
  return request({
    url: userApi.enable,
    method: 'put',
    data: parameter
  })
}

export function list(parameter) {
  return request({
    url: userApi.list,
    method: 'get',
    params: parameter
  })
}
export function list4Student(parameter) {
  return request({
    url: userApi.list4Student,
    method: 'get',
    params: parameter
  })
}
export function create(parameter) {
  return request({
    url: userApi.create,
    method: 'post',
    data: parameter
  })
}
export function update(parameter) {
  const { id } = parameter
  delete parameter.id
  return request({
    url: userApi.update(id),
    method: 'put',
    data: parameter
  })
}
export function detail(parameter) {
  return request({
    url: userApi.detail(parameter),
    method: 'get'
  })
}
export function remove(parameter) {
  return request({
    url: userApi.remove(parameter),
    method: 'delete'
  })
}
export function getInfo() {
  return request({
    url: userApi.getInfo,
    method: 'get'
  })
}
