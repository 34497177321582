import router from './router'
import store from './store'
import storage from 'store'
import NProgress from 'nprogress' // progress bar
import '@/components/NProgress/nprogress.less' // progress bar custom style
import { setDocumentTitle, domTitle } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { i18nRender } from '@/locales'
import { getInfo } from '@/api/user'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const allowList = ['login', 'login/bjesr', 'changepassword', 'register', 'registerResult'] // no redirect allowList
const loginRoutePath = '/user/login' // 正常登录
const login2RoutePath = '/user/login/bjesr' // 北京教育科学研究院登录
// 获取权限生成菜单
const initPermission = function (to, from, next) {
  // request login userInfo
  store.dispatch('getInfo').then(res => {
    const roles = res.data && res.data.menuSet
    // generate dynamic router
    store.dispatch('GenerateRoutes', { roles }).then(() => {
      // 根据roles权限生成可访问的路由表
      // 动态添加可访问路由表
      router.$addRoutes(store.getters.addRouters)

      // 请求带有 redirect 重定向时，登录自动重定向到该地址
      const redirect = decodeURIComponent(from.query.redirect || to.path)
      if (to.path === redirect) {
        // set the replace: true so the navigation will not leave a history record
        next({ ...to, replace: true })
      } else {
        // 跳转到目的路由
        next({ path: redirect })
      }
    })
  })
}

router.beforeEach((to, from, next) => {
  NProgress.start() // start progress bar
  to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${i18nRender(to.meta.title)} - ${domTitle}`)
  // 判断登录界面并存储
  if (to.path === loginRoutePath || to.path === login2RoutePath) {
    store.commit('SET_LOGIN_PATH', to.path)
    storage.set('loginPath', to.path)
  }
  /* has token */
  if (storage.get(ACCESS_TOKEN)) {
    if (to.path === loginRoutePath || to.path === login2RoutePath) {
      next()
      NProgress.done()
    } else {
      // check login user.roles is null
      if (store.getters.roles.length === 0) {
        initPermission(to, from, next)
      } else {
        // 判断是否登录其他账号
        getInfo().then(res => {
          const currentName = res.data.username
          if (store.getters.nickname !== currentName) {
            const roles = res.data && res.data.menuSet
            store.dispatch('getInfo')
            store.dispatch('GenerateRoutes', { roles }).then(() => {
              // 根据roles权限生成可访问的路由表
              // 动态添加可访问路由表
              router.$addRoutes(store.getters.addRouters)
              next({ name: 'index' })
            })
          } else {
            next()
          }
        })
      }
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next()
    } else {
      next({ path: '/user/login', query: { redirect: to.fullPath } })
      NProgress.done() // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
})

router.afterEach(() => {
  NProgress.done() // finish progress bar
})
