<template>
  <a-upload :showUploadList="false" :customRequest="customRequest" :beforeUpload="beforeUpload" @change="handleChange">
    <div>
      <a-button type="round" :disabled="uploading">
        <a-icon :type="uploading ? 'loading' : 'upload'" />{{ uploadText }}
      </a-button>
    </div>
  </a-upload>
</template>
<script>
import fileMD5 from '@/utils/file'
import { put, getFileExtention, getFileType } from './upload'
export default {
  props: {
    accept: {
      type: Array,
      default() {
        return []
      }
    },
    limit: {
      type: Number,
      default: 0
    },
    // 上传文件下载或者预览（默认下载）
    isDownload: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      uploading: false,
      per: 0
    }
  },
  computed: {
    uploadText() {
      return this.uploading ? (this.per < 100 ? `正在上传 ${this.per}%` : '文件上传完毕，正在解析') : '上传文件'
    }
  },
  methods: {
    async customRequest(action) {
      const file = action.file
      const { name, size } = file
      const suffix = getFileExtention(name)
      const { $notification } = this
      const realName = name
      try {
        const _name = await fileMD5(file)
        const type = getFileType(file.name)
        const name = type === 'VIDEO' ? `origin.${_name}` : _name
        put(
          { name, file, type },
          {
            progress: e => {
              this.per = parseInt(e * 100)
            },
            headers: {
              'Content-Disposition': this.isDownload ? 'attachment' : 'inline '
            }
          }
        )
          .then(result => {
            const { url: _url } = result
            const url = type === 'VIDEO' ? _url.replaceAll('origin', 'output') : _url
            this.$emit('success', {
              name: type === 'VIDEO' ? `output.${realName}` : realName,
              size,
              suffix,
              type,
              url,
              value: _name
            })
            this.$message.success(`${action.file.name} 文件上传成功`)
          })
          .catch(() => {
            $notification['error']({ message: '提示', description: '上传文件服务错误' })
          })
          .finally(() => (this.uploading = false))
      } catch (error) {
        console.log(error)
        $notification['error']({ message: '提示', description: '脚本错误' })
      }
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploading = true
      }
    },
    beforeUpload(file) {
      if (this.limit > 0 && file.size > this.limit) {
        let deep = 0
        let { limit } = this
        while (limit > 1024 && deep < 3) {
          limit = parseInt(limit / 1024)
          deep++
        }
        const units = ['B', 'KB', 'MB', 'GB']
        const msg = `${file.name} 文件大于${limit}${units[deep]}`
        this.$message.error(msg)
        this.$emit('error', msg)
        return false
      }
      if (this.accept.length > 0 && !this.accept.includes(getFileExtention(file.name).toLowerCase())) {
        const msg = `请选择 ${this.accept.join('/')} 格式的文件`
        this.$message.error(msg)
        this.$emit('error', msg)
        return false
      }
      return true
    }
  }
}
</script>
