<template>
  <div id="userLayout" :class="['user-layout-wrapper', isMobile && 'mobile']">
    <div class="container">
      <div class="logo">
        <img v-if="loginPath == '/user/login'" src="~@/assets/ico_logo.png" alt="加载错误..." />
        <img v-else style="width: 16vw;" src="~@/assets/bgjykxyjy-logo.png" alt="加载错误..." />
      </div>

      <div class="content">
        <div class="content-side"></div>
        <div class="content-wrap">
          <div class="content-main">
            <router-view />
          </div>
          <div class="copyright" v-if="loginPath == '/user/login'">
            <p>北大医疗脑健康出品</p>
            <a-popover placement="right">
              <template #content>
                <p style="width:160px;">
                  <span style="font-weight:600;">软件名称：</span>嗨宝快学机构SaaS系统
                </p>
                <p>
                  <span style="font-weight:600;">版本号：</span>V.1.0
                </p>
              </template>
              <img class="tips-icon" src="~@/assets/ico-tips.png" />
            </a-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'UserLayout',
  mixins: [deviceMixin],
  computed: {
    ...mapGetters({
      loginPath: 'getLoginPath',
    }),
  },
  mounted() {
    document.body.classList.add('userLayout')
  },
  beforeDestroy() {
    document.body.classList.remove('userLayout')
  },
}
</script>

<style lang="less" scoped>
#userLayout.user-layout-wrapper {
  height: 100%;

  &.mobile {
    .container {
      .main {
        max-width: 368px;
        width: 98%;
      }
    }
  }

  .container {
    width: 100vw;
    height: 100vh;
    background: url(~@/assets/bg_login.png) no-repeat center;
    background-size: cover;
    overflow: hidden;

    .logo {
      margin: 3.8vh 0 0 3vw;

      img {
        width: 11vw;
      }
    }

    .content {
      display: flex;
      align-items: center;
      min-height: 90vh;
      margin: 2.9vh 0 0;

      .content-side {
        width: 35vw;
        height: 56vh;
        margin: 0 0 0 11.1vw;
        background: url(~@/assets/ico_sider.png) center no-repeat;
        background-size: contain;
      }

      .content-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 4.6vw 0 12.2vw;
        overflow: hidden;

        .content-main {
          padding: 7.1vh 5vw 0;
          font-weight: normal;
          background-color: #ffffff;
          border-radius: 20px;
        }
        .copyright {
          display: flex;
          align-items: center;
          margin: 4.5vh 0 5.1vh;
          color: #ffffff;
          font-size: 24px;
          text-align: center;
          .tips-icon {
            margin-left: 10px;
            margin-bottom: 1.4rem;
            width: 27px;
            height: 27px;
            cursor: pointer;
          }
        }
      }
    }

    .footer {
      position: relative;
      text-align: right;
      .links {
        margin-bottom: 8px;
        font-size: 14px;
        a {
          color: rgba(0, 0, 0, 0.45);
          transition: all 0.3s;
          &:not(:last-child) {
            margin-right: 40px;
          }
        }
      }
    }
  }
}
</style>
