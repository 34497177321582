import OSS from 'ali-oss'
import * as uploadApi from '@/api/upload'
let ossInfo = {
  accessKeyId: null,
  accessKeySecret: null,
  bucketList: null,
  endpoint: null
}
export function put({ name, file, type }, options = {}) {
  try {
    return new Promise((resolve, reject) => {
      // if (!ossInfo.accessKeyId) {
      return uploadApi.aliSignature().then(res => {
        ossInfo = res.data
        resolve(ossInfo)
      })
      // } else {
      //   resolve(ossInfo)
      // }
    }).then(ossInfo => {
      const { accessKeyId, accessKeySecret, bucket: bucketList, region, securityToken: stsToken } = ossInfo
      const extension = getFileExtention(file.name)

      if (!type) {
        type = getFileType(extension)
      }
      const target = bucketList.find(v => v.type === type)
      const { bucket, host } = target
      const client = new OSS({
        region,
        accessKeyId,
        accessKeySecret,
        bucket,
        stsToken
      })

      return new Promise((resolve, reject) => {
        client
          .multipartUpload(name + '.' + extension, file, options)
          .then(res => {
            // 处理添加-internal后文件无法访问
            const _host = host.indexOf('-internal') ? host.replaceAll('-internal', '') : host
            resolve({ url: _host + '/' + res.name, type })
          })
          .catch(err => reject(err))
      })
    })
  } catch (e) {
    console.log(e)
  }
}

export function getFileExtention(name) {
  const tmpcnt = name.lastIndexOf('.')
  return name.substring(tmpcnt + 1)
}

export function getFileType(extension) {
  if (extension.includes('.')) {
    extension = getFileExtention(extension)
  }
  if (isImage(extension)) {
    return 'IMAGE'
  } else if (isVideo(extension)) {
    return 'VIDEO'
  } else {
    return 'IMAGE'
  }
}

export function isImage(name, extensions = ['jpg', 'jpeg', 'png', 'bmp']) {
  const extension = getFileExtention(name)
  return extensions.includes(extension.toLowerCase())
}

export function isVideo(name) {
  const extension = getFileExtention(name)
  return ['mp4'].includes(extension.toLowerCase())
}
