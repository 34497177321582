import request from '@/utils/request'

const userApi = {
  login: '/login/token',
  refreshToken: '/login/token/refresh',
  logout: '/login/out',
  verificationPassword: oldpassword => `/user/verification/password/${oldpassword}`,
  changePassword: newpassword => `/user/password/${newpassword}`,
  changeByPhone: '/user/password/by/phone',
  sendCode: cellphone => `/app/verificationCode/send/${cellphone}`,
  verificationCode: '/app/verificationCode'
}

export function login(param) {
  return request({
    url: userApi.login,
    method: 'post',
    data: param
  })
}

export function refreshToken(param) {
  return request({
    url: userApi.refreshToken,
    method: 'post',
    params: param
  })
}

export function logout() {
  return request({
    url: userApi.logout,
    method: 'put'
  })
}
// 验证旧密码
export function verificationPassword(param) {
  const { oldpassword } = param
  return request({
    url: userApi.verificationPassword(encodeURIComponent(oldpassword)),
    method: 'PATCH'
  })
}
// 修改密码
export function changePassword(param) {
  const { newpassword } = param
  return request({
    url: userApi.changePassword(encodeURIComponent(newpassword)),
    method: 'PATCH'
  })
}
// 手机号修改密码
export function changeByPhone(param) {
  return request({
    url: userApi.changeByPhone,
    method: 'post',
    data: param
  })
}
// 发送验证码
export function sendCode(param) {
  const { cellphone } = param
  return request({
    url: userApi.sendCode(cellphone),
    method: 'get'
  })
}
// 验证验证码
export function verifCode(param) {
  return request({
    url: userApi.verificationCode,
    method: 'post',
    data: param
  })
}
