import SparkMD5 from 'spark-md5'

export default function fileMd5(file) {
  const promise = new Promise(function(resolve, reject) {
    const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
    const chunkSize = 2097152 // Read in chunks of 2MB
    const chunks = Math.ceil(file.size / chunkSize)
    let currentChunk = 0
    const spark = new SparkMD5.ArrayBuffer()
    const fileReader = new FileReader()

    fileReader.onload = function(e) {
      // console.log('read chunk nr', currentChunk + 1, 'of', chunks);
      spark.append(e.target.result) // Append array buffer
      currentChunk++

      if (currentChunk < chunks) {
        loadNext()
      } else {
        // console.log('finished loading');
        const res = spark.end()
        // console.info('computed hash:', spark.end()); // Compute hash
        resolve(res)
      }
    }

    fileReader.onerror = function() {
      // console.warn('oops, something went wrong.');
      // eslint-disable-next-line
      reject('oops, something went wrong.')
    }

    function loadNext() {
      const start = currentChunk * chunkSize
      const end = start + chunkSize >= file.size ? file.size : start + chunkSize

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    }

    loadNext()
  })
  return promise
}
