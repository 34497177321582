// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import i18n from './locales'
import { VueAxios } from './utils/request'
import ProLayout, { PageHeaderWrapper } from '@ant-design-vue/pro-layout'
import { Icon } from 'ant-design-vue'
import { Select, Cascader } from '@/components/_heath'
import Pagination from 'ant-design-vue/lib/pagination'
// import 'ant-design-vue/dist/antd.css'

import bootstrap from './core/bootstrap'
import './core/lazy_use' // use lazy load components
import './permission' // permission control
import './utils/filter' // global filter
import './global.less'

import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
Vue.component(Pagination.name, Pagination) // global style

Vue.config.productionTip = false

// mount axios to `Vue.$http` and `this.$http`
Vue.use(VueAxios)
// use pro-layout components
Vue.component('pro-layout', ProLayout)
Vue.component('page-container', PageHeaderWrapper)
Vue.component('page-header-wrapper', PageHeaderWrapper)
Vue.use(Viewer)

const HIcon = Icon.createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/font_2286911_ngh8j738ics.js' // 在 iconfont.cn 上生成
})
Vue.component('h-icon', HIcon)

Vue.component('h-select', Select)
Vue.component('h-cascader', Cascader)

Vue.prototype.$formItemLayout = {
  labelCol: {
    xs: {
      span: 24
    },
    sm: { span: 4, offset: 1 }
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 10
    }
  }
}

Vue.prototype.$formItemLayout4Detail = {
  labelCol: {
    xs: {
      span: 24
    },
    sm: { span: 4, offset: 1 }
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: { span: 14 }
  }
}

Vue.prototype.$formItemLayout4DetailSm = {
  labelCol: {
    xs: {
      span: 24
    },
    sm: { span: 3 }
  },
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: { span: 14 }
  }
}

Vue.prototype.$formItemLayoutWithoutLabel = {
  wrapperCol: {
    xs: {
      span: 24
    },
    sm: {
      span: 10,
      offset: 5
    }
  }
}

new Vue({
  router,
  store,
  i18n,
  // init localstorage, vuex
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
