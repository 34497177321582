import {
  UserLayout,
  BasicLayout,
  // eslint-disable-next-line
  BlankLayout,
  // keep-alive
  RouteView
} from '@/layouts'
// eslint-disable-next-line
import {
  order,
  ServiceManager,
  homeSchoolService,
  tool,
  goods,
  device,
  permission,
  redemptionCode,
  service,
  skillManagement,
  student,
  subject,
} from '@/core/icons'

// const RouteView = {
//   name: 'RouteView',
//   render: (h) => h('router-view')
// }

export const asyncRouterMap = [{
  path: '/',
  name: 'index',
  component: BasicLayout,
  meta: {
    title: '首页'
  },
  children: [
    // permission
    {
      path: '/permission',
      name: 'PermissionManagementMenu',
      redirect: '/permission/role',
      component: RouteView,
      meta: {
        title: '权限管理',
        keepAlive: true,
        icon: permission,
        permission: ['menu_manage']
      },
      children: [{
        path: '/permission/organization',
        name: 'PermissionOrganizationList',
        component: () => import('@/views/permission/organization/list'),
        meta: {
          title: '机构管理',
          keepAlive: true,
          permission: ['mechanism_manage']
        }
      },
      {
        path: '/permission/organization/childlist/:id',
        name: 'PermissionOrganizationChildlist',
        hidden: 'true',
        component: () => import('@/views/permission/organization/childlist'),
        meta: {
          title: '子机构列表',
          keepAlive: true,
          permission: ['mechanism_manage']
        }
      },
      {
        path: '/permission/organization/userlist/:id',
        name: 'PermissionOrganizationUserlist',
        hidden: 'true',
        component: () => import('@/views/permission/organization/userlist'),
        meta: {
          title: '人员列表',
          keepAlive: true,
          permission: ['mechanism_manage']
        }
      },
      {
        path: '/permission/role',
        name: 'PermissionRoleList',
        component: () => import('@/views/permission/role/list'),
        meta: {
          title: '角色管理',
          keepAlive: true,
          permission: ['role_manage']
        }
      },
      {
        path: '/permission/role/create',
        name: 'PermissionRoleCreate',
        component: () => import('@/views/permission/role/create'),
        hidden: true,
        meta: {
          title: '新增角色',
          keepAlive: true,
          permission: ['role_manage']
        }
      },
      {
        path: '/permission/role/edit/:id',
        name: 'PermissionRoleEdit',
        component: () => import('@/views/permission/role/edit'),
        hidden: true,
        meta: {
          title: '编辑角色',
          keepAlive: true,
          permission: ['role_manage']
        }
      },
      {
        path: '/permission/post',
        name: 'PermissionPostList',
        component: () => import('@/views/permission/post/list'),
        meta: {
          title: '职务管理',
          keepAlive: true,
          permission: ['post_manage']
        }
      },
      {
        path: '/permission/user',
        name: 'PermissionUserList',
        component: () => import('@/views/permission/user/list'),
        meta: {
          title: '员工管理',
          keepAlive: true,
          permission: ['user_manage']
        }
      },
      {
        path: '/permission/user/create',
        name: 'PermissionUserCreate',
        hidden: true,
        component: () => import('@/views/permission/user/create'),
        meta: {
          title: '新增用户',
          keepAlive: true,
          permission: ['user_manage']
        }
      },
      {
        path: '/permission/user/edit/:id',
        name: 'PermissionUserEdit',
        hidden: true,
        component: () => import('@/views/permission/user/edit'),
        meta: {
          title: '编辑用户',
          keepAlive: true,
          permission: ['user_manage']
        }
      }
      ]
    },
    // skill-management
    {
      path: '/skill-management',
      name: 'SkillManagementMenu',
      redirect: '/skill-management/phase',
      component: RouteView,
      meta: {
        title: '技能管理',
        icon: skillManagement,
        permission: ['skills_manage_menu']
      },
      children: [{
        path: '/skill-management/phase',
        name: 'SkillManagementPhaseList',
        component: () => import('@/views/skill-management/phase/list'),
        meta: {
          title: '阶段列表',
          keepAlive: true,
          permission: ['phases_manage']
        }
      },
      {
        path: '/skill-management/phase/create',
        name: 'SkillManagementPhaseCreate',
        hidden: true,
        component: () => import('@/views/skill-management/phase/create'),
        meta: {
          title: '新增阶段',
          keepAlive: true,
          permission: ['phases_manage']
        }
      },
      {
        path: '/skill-management/phase/edit/:id',
        name: 'SkillManagementPhaseEdit',
        hidden: true,
        component: () => import('@/views/skill-management/phase/edit'),
        meta: {
          title: '编辑阶段',
          keepAlive: true,
          permission: ['phases_manage']
        }
      },
      {
        path: '/skill-management/phase/:id',
        name: 'SkillManagementPhaseDetail',
        hidden: true,
        component: () => import('@/views/skill-management/phase/detail'),
        meta: {
          title: '查看阶段',
          permission: ['phases_manage']
        }
      },
      {
        path: '/skill-management/skill',
        name: 'SkillManagementSkillList',
        component: () => import('@/views/skill-management/skill/list'),
        meta: {
          title: '技能包列表',
          keepAlive: true,
          permission: ['skills_manage']
        }
      },
      {
        path: '/skill-management/skill/base',
        name: 'SkillManagementBaseSkillList',
        component: () => import('@/views/skill-management/skill/base/index'),
        hidden: true,
        meta: {
          title: '基础技能排序',
          permission: ['skills_manage']
        }
      },
      {
        path: '/skill-management/skill/create',
        name: 'SkillManagementSkillCreate',
        hidden: true,
        component: () => import('@/views/skill-management/skill/create'),
        meta: {
          title: '新增技能包',
          keepAlive: true,
          permission: ['skills_manage']
        }
      },
      {
        path: '/skill-management/skill/edit/:id',
        name: 'SkillManagementSkillEdit',
        hidden: true,
        component: () => import('@/views/skill-management/skill/edit'),
        meta: {
          title: '编辑技能包',
          keepAlive: true,
          permission: ['skills_manage']
        }
      },
      {
        path: '/skill-management/skill/:id',
        name: 'SkillManagementSkillDetail',
        hidden: true,
        component: () => import('@/views/skill-management/skill/detail'),
        meta: {
          title: '技能包详情',
          keepAlive: true,
          permission: ['skills_manage']
        }
      },
      {
        path: '/skill-management/skill/:skillId/phase/create',
        name: 'SkillManagementSkillPhaseCreate',
        hidden: true,
        component: () => import('@/views/skill-management/skill/phase/create'),
        meta: {
          title: '新增技能包阶段',
          keepAlive: true,
          permission: ['skills_manage']
        }
      },
      {
        path: '/skill-management/skill/:skillId/phase/edit/:id',
        name: 'SkillManagementSkillPhaseEdit',
        hidden: true,
        component: () => import('@/views/skill-management/skill/phase/edit'),
        meta: {
          title: '编辑技能包阶段',
          keepAlive: true,
          permission: ['skills_manage']
        }
      },
      {
        path: '/skill-management/field',
        name: 'SkillManagementFieldList',
        component: () => import('@/views/skill-management/field/list'),
        meta: {
          title: '领域管理',
          keepAlive: true,
          permission: ['fields_manage']
        }
      },
      {
        path: '/skill-management/field/create',
        name: 'SkillManagementFieldCreate',
        hidden: true,
        component: () => import('@/views/skill-management/field/create'),
        meta: {
          title: '新增分类',
          keepAlive: true,
          permission: ['fields_manage']
        }
      },
      {
        path: '/skill-management/field/edit/:id',
        name: 'SkillManagementFieldEdit',
        hidden: true,
        component: () => import('@/views/skill-management/field/edit'),
        meta: {
          title: '编辑分类',
          keepAlive: true,
          permission: ['fields_manage']
        }
      }
      ]
    },

    // subject
    {
      path: '/subject',
      name: 'SubjectManagementMenu',
      redirect: '/subject',
      component: RouteView,
      hideChildrenInMenu: true,
      meta: {
        title: '题库管理',
        icon: subject,
        permission: ['subjects_manage']
      },
      children: [{
        path: '/subject',
        name: 'SubjectManagementList',
        component: () => import('@/views/subject/list'),
        meta: {
          title: '题库管理',
          keepAlive: true,
          permission: ['subjects_manage']
        }
      },
      {
        path: '/subject/create',
        name: 'SubjectManagementCreate',
        hidden: true,
        component: () => import('@/views/subject/create'),
        meta: {
          title: '新增题',
          keepAlive: true,
          permission: ['subjects_manage']
        }
      },
      {
        path: '/subject/edit/:id',
        name: 'SubjectManagementEdit',
        hidden: true,
        component: () => import('@/views/subject/edit'),
        meta: {
          title: '编辑题',
          keepAlive: true,
          permission: ['subjects_manage']
        }
      }
      ]
    },

    // service-manager
    {
      path: '/service-manager',
      name: 'ServiceManagerMenu',
      redirect: '/service-manager/list',
      hideChildrenInMenu: true,
      component: RouteView,
      meta: {
        title: '服务管理',
        keepAlive: true,
        icon: ServiceManager,
        permission: ['service_manage']
      },
      children: [{
        path: '/service-manager/list',
        name: 'ServiceManagerList',
        component: () => import('@/views/service-manager/list'),
        meta: {
          title: '服务管理列表',
          keepAlive: true,
          permission: ['service_manage']
        }
      },
      {
        path: '/service-manager/create',
        name: 'ServiceManagerCreate',
        component: () => import('@/views/service-manager/create'),
        hidden: true,
        meta: {
          title: '服务管理新建',
          keepAlive: true,
          permission: ['service_manage']
        }
      },
      {
        path: '/service-manager/edit/:id',
        name: 'ServiceManagerEdit',
        component: () => import('@/views/service-manager/edit'),
        hidden: true,
        meta: {
          title: '服务管理编辑',
          permission: ['service_manage']
        }
      },
      {
        path: '/service-manager/detail/:id',
        name: 'ServiceManagerDetail',
        component: () => import('@/views/service-manager/detail'),
        hidden: true,
        meta: {
          title: '服务管理查看',
          permission: ['service_manage']
        }
      }
      ]
    },

    // goods-management
    {
      path: '/goods',
      name: 'GoodsManagementMenu',
      redirect: '/goods/skill',
      component: RouteView,
      meta: {
        title: '商品管理',
        keepAlive: true,
        icon: goods,
        permission: ['goods_manage']
      },
      children: [{
        path: '/goods/skill',
        name: 'GoodsManagementSkillList',
        component: () => import('@/views/goods/skill/list'),
        meta: {
          title: '技能商品管理',
          keepAlive: true,
          permission: ['goods_skill_manage']
        }
      },
      {
        path: '/goods/skill/create',
        name: 'GoodsManagementSkillCreate',
        component: () => import('@/views/goods/skill/create'),
        hidden: true,
        meta: {
          title: '选择技能包',
          keepAlive: true,
          permission: ['goods_skill_manage']
        }
      },
      {
        path: '/goods/skill/edit/:id',
        name: 'GoodsManagementSkillEdit',
        hidden: true,
        component: () => import('@/views/goods/skill/edit'),
        meta: {
          title: '编辑技能包',
          permission: ['goods_skill_manage']
        }
      },
      {
        path: '/goods/skill/detail/:id',
        name: 'GoodsManagementSkillDetail',
        hidden: true,
        component: () => import('@/views/goods/skill/detail'),
        meta: {
          title: '技能包查看',
          permission: ['goods_skill_manage']
        }
      },
      {
        path: '/goods/service',
        name: 'GoodsManagementServiceList',
        component: () => import('@/views/goods/service/list'),
        meta: {
          title: '服务商品管理',
          keepAlive: true,
          permission: ['goods_service_manage']
        }
      },
      {
        path: '/goods/service/individual/create',
        name: 'GoodsManagementServiceIndividualCreate',
        component: () => import('@/views/goods/service/individual/create'),
        hidden: true,
        meta: {
          title: '上架服务-单项',
          keepAlive: true,
          permission: ['goods_service_manage']
        }
      },
      {
        path: '/goods/service/individual/detail/:id',
        name: 'GoodsManagementServiceIndividualDetail',
        component: () => import('@/views/goods/service/individual/detail'),
        hidden: true,
        meta: {
          title: '上架服务-查看-单项',
          permission: ['goods_service_manage']
        }
      },
      {
        path: '/goods/service/individual/edit/:id',
        name: 'GoodsManagementServiceIndividualEdit',
        component: () => import('@/views/goods/service/individual/edit'),
        hidden: true,
        meta: {
          title: '上架服务-编辑-单项',
          permission: ['goods_service_manage']
        }
      },
      {
        path: '/goods/service/combination/create',
        name: 'GoodsManagementServiceCombinationCreate',
        component: () => import('@/views/goods/service/combination/create'),
        hidden: true,
        meta: {
          title: '上架服务-组合',
          keepAlive: true,
          permission: ['goods_service_manage']
        }
      },
      {
        path: '/goods/service/combination/detail/:id',
        name: 'GoodsManagementServiceCombinationDetail',
        component: () => import('@/views/goods/service/combination/detail'),
        hidden: true,
        meta: {
          title: '上架服务-查看-组合',
          permission: ['goods_service_manage']
        }
      },
      {
        path: '/goods/service/combination/edit/:id',
        name: 'GoodsManagementServiceCombinationEdit',
        component: () => import('@/views/goods/service/combination/edit'),
        hidden: true,
        meta: {
          title: '上架服务-编辑-组合',
          permission: ['goods_service_manage']
        }
      }
      ]
    },

    // order
    {
      path: '/order',
      name: 'OrderMenu',
      redirect: '/order',
      hideChildrenInMenu: true,
      component: RouteView,
      meta: {
        title: '订单管理',
        keepAlive: true,
        icon: order,
        permission: ['order_manage']
      },
      children: [{
        path: '/order',
        name: 'OrderList',
        component: () => import('@/views/order/list'),
        meta: {
          title: '订单列表',
          keepAlive: true,
          permission: ['order_manage']
        }
      },
      {
        path: '/order/:id',
        name: 'OrderDetail',
        component: () => import('@/views/order/detail'),
        meta: {
          title: '订单详情',
          keepAlive: true,
          permission: ['order_manage']
        }
      }
      ]
    },

    // device
    {
      path: '/device',
      name: 'DeviceMenu',
      redirect: '/device/list',
      hideChildrenInMenu: true,
      component: RouteView,
      meta: {
        title: '设备录入',
        keepAlive: true,
        icon: device,
        permission: ['equipment_manage']
      },
      children: [{
        path: '/device/list',
        name: 'DeviceList',
        component: () => import('@/views/device/list'),
        meta: {
          title: '设备录入',
          keepAlive: true,
          permission: ['equipment_manage']
        }
      }]
    },

    // redemption-code
    {
      path: '/redemption-code',
      name: 'RedemptionCodeManagementMenu',
      redirect: '/redemption-code',
      component: RouteView,
      hideChildrenInMenu: true,
      meta: {
        title: '兑换码管理',
        icon: redemptionCode,
        permission: ['redemptionCode_manage']
      },
      children: [{
        path: '/redemption-code',
        name: 'RedemptionCodeManagementIndex',
        component: () => import('@/views/redemption-code/index'),
        meta: {
          title: '兑换码',
          keepAlive: true,
          permission: ['redemptionCode_manage']
        }
      }]
    },

    // student
    {
      path: '/student',
      name: 'StudentManagementMenu',
      redirect: '/student',
      component: RouteView,
      hideChildrenInMenu: true,
      meta: {
        title: '学员管理',
        icon: student,
        permission: ['student_manage']
      },
      children: [{
        path: '/student',
        name: 'StudentManagementList',
        component: () => import('@/views/student/list'),
        meta: {
          title: '学员管理',
          keepAlive: true,
          permission: ['student_manage']
        }
      },
      {
        path: '/student/edit/:id',
        name: 'StudentManagementEdit',
        component: () => import('@/views/student/edit'),
        meta: {
          title: '学员编辑',
          keepAlive: false,
          permission: ['student_manage']
        }
      },
      {
        path: '/student/add',
        name: 'StudentManagementAdd',
        component: () => import('@/views/student/add'),
        meta: {
          title: '学员添加',
          keepAlive: true,
          permission: ['student_manage']
        }
      },
      {
        path: '/student/bind/:id',
        name: 'StudentManagementBind',
        component: () => import('@/views/student/bind'),
        meta: {
          title: '学员绑定设备',
          keepAlive: true,
          permission: ['student_manage']
        }
      }
      ]
    },

    // service
    {
      path: '/home-school-service',
      name: 'homeSchoolTrainingServiceMenu',
      redirect: '/home-school-service',
      component: RouteView,
      hideChildrenInMenu: true,
      meta: {
        title: '家校训练服务',
        icon: homeSchoolService,
        permission: ['school_training_service_manage']
      },
      children: [{
        path: '/home-school-service',
        name: 'homeSchoolList',
        component: () => import('@/views/home-school-service/list'),
        meta: {
          title: '家校训练服务',
          keepAlive: true,
          permission: ['school_training_service_manage']
        }
      },

      // {
      //   path: '/home-school-service/evaluation-review',
      //   name: 'homeSchoolEvaluationReview',
      //   component: resolve => require(['@/views/home-school-service/evaluationReview/evaluationReview'], resolve),
      //   meta: {
      //     title: '家校训练服务-重新评估审核',
      //     keepAlive: true,
      //     permission: ['school_training_service_manage']
      //   }
      // },

      {
        path: '/home-school-service/classes-begin',
        name: 'homeSchoolClassesBegin',
        component: resolve => require(['@/views/home-school-service/classes-begin/classes-begin'], resolve),
        meta: {
          title: '家校训练服务-开始上课',
          keepAlive: true,
          permission: ['school_training_service_manage']
        }
      },

      {
        path: '/home-school-service/training-completed',
        name: 'homeSchoolTrainingCompleted',
        component: resolve =>
          require(['@/views/home-school-service/training-completed/training-completed'], resolve),
        meta: {
          title: '家校训练服务-训练完成',
          keepAlive: true,
          permission: ['school_training_service_manage']
        }
      },

      {
        path: '/home-school-service/detail/:id',
        name: 'homeSchoolDetail',
        hidden: true,
        component: () => import('@/views/home-school-service/detail'),
        meta: {
          title: '家校训练服务详情',
          permission: ['school_training_service_manage']
        }
      },

      {
        path: '/home-school-service/go-to-class',
        name: 'homeSchoolGoToClass',
        hidden: true,
        component: () => import('@/views/home-school-service/go-to-class/go-to-class'),
        meta: {
          title: '家校训练服务-上课',
          permission: ['school_training_service_manage']
        }
      },
      {
        path: '/home-school-service/edit/plan/skill-phase/:id',
        name: 'homeSchoolPlanSkillPhaseEdit',
        hidden: true,
        component: () => import('@/views/home-school-service/edit/plan/skill-phase'),
        meta: {
          title: '编辑训练计划阶段',
          keepAlive: true,
          permission: ['school_training_service_manage']
        }
      }
      ]
    },

    // tool
    {
      path: '/tool',
      name: 'ToolMenu',
      redirect: '/tool/list',
      hideChildrenInMenu: true,
      component: RouteView,
      meta: {
        title: '评估工具',
        keepAlive: true,
        icon: tool,
        permission: ['assessment_equipment_manage']
      },
      children: [{
        path: '/tool/list',
        name: 'ToolList',
        component: () => import('@/views/tool/list'),
        meta: {
          title: '评估工具',
          keepAlive: true,
          permission: ['assessment_equipment_manage']
        }
      },
      {
        path: '/tool/detail/:id',
        name: 'ToolDetail',
        component: () => import('@/views/tool/detail'),
        hidden: true,
        meta: {
          title: '用户详情',
          permission: ['assessment_equipment_manage']
        }
      }
      ]
    },
    // 体验机构信息

    {
      path: '/experience',
      name: 'experienceMenu',
      redirect: '/experience/list',
      hideChildrenInMenu: true,
      component: RouteView,
      meta: {
        title: '体验登记信息',
        keepAlive: true,
        icon: service,
        permission: ['visitor_manage']
      },
      children: [{
        path: '/experience/list',
        name: 'experienceList',
        component: () => import('@/views/experience/list'),
        meta: {
          title: '体验登记信息',
          keepAlive: true,
          permission: ['visitor_manage']
        }
      }]
    }
  ]
},
{
  path: '*',
  redirect: '/404',
  hidden: true
}
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import( /* webpackChunkName: "user" */ '@/views/user/Login')
      },
      {
        path: 'login/bjesr',
        name: 'login/bjesr',
        component: () => import( /* webpackChunkName: "user" */ '@/views/user/bjesr/login')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      },
      {
        path: 'changepassword',
        name: 'changepassword',
        component: () => import( /* webpackChunkName: "user" */ '@/views/user/ChangePassword')
      }
    ]
  },
  {
    path: '/404',
    component: () => import( /* webpackChunkName: "fail" */ '@/views/exception/404')
  }
]