<template>
  <div>
    <div v-if="url && !loading" class="avatar-wrap" @mouseenter="showMask = true" @mouseleave="showMask = false">
      <img :src="url" alt="avatar" />
      <div v-if="showMask" class="avatar-mask">
        <a-icon
          class="reupload"
          type="picture"
          style="margin-right:8px"
          @click="$refs.upload.$el.querySelector('input').click()"
        />
        <a-icon class="remove" type="delete" @click="$emit('remove')" />
      </div>
    </div>
    <a-upload
      ref="upload"
      class="avatar-uploader"
      :showUploadList="false"
      :customRequest="customRequest"
      :beforeUpload="beforeUpload"
      @change="handleChange"
    >
      <div v-if="!url || loading" class="avatar-uploader-wrap">
        <a-icon :type="loading ? 'loading' : 'picture'" />
        <div class="ant-upload-text">
          上传图片
        </div>
      </div>
    </a-upload>
  </div>
</template>

<script>
import fileMD5 from '@/utils/file'
import { put, isImage } from './upload'
export default {
  props: {
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      showMask: false
    }
  },
  methods: {
    async customRequest(action) {
      const file = action.file
      const { $notification } = this
      try {
        const name = await fileMD5(file)
        put(
          { name, file, type: 'IMAGE' },
          {
            progress: e => {
              console.log(e)
            }
          }
        )
          .then(result => {
            const { url } = result
            this.$emit('success', url)
          })
          .catch(() => {
            $notification['error']({ message: '提示', description: '上传文件服务错误' })
          })
          .finally(() => (this.loading = false))
      } catch (error) {
        console.log(error)
        $notification['error']({ message: '提示', description: '脚本错误' })
      }
    },
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        this.showMask = false
      }
    },
    beforeUpload(file) {
      const isImg = isImage(file.name)
      if (!isImg) {
        this.$message.error('只允许上传图片')
      }
      const isLt5MK = file.size / 1024 < 1024 * 5
      if (!isLt5MK) {
        this.$message.error('图片不能超过5MB')
      }
      return isImg && isLt5MK
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-uploader-wrap {
  cursor: pointer;
  width: 142px;
  height: 80px;
  background: @comp-bg-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: @border-radius-base;
  color: @disabled-color;
  border: 1px solid @border-color-base;
  /deep/ i {
    font-size: 18px;
  }
  &:hover {
    color: @primary-color-8;
    border-color: @primary-color-8;
  }
}

.avatar-wrap {
  cursor: pointer;
  box-sizing: border-box;
  width: 142px;
  height: 80px;
  border: 1px solid @border-color-base;
  border-radius: @border-radius-base;
  position: relative;
  img {
    border-radius: @border-radius-base;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .avatar-mask {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    position: absolute;
    border-radius: @border-radius-base;
    top: 0;
    left: -1px;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.45);
    .reupload:hover {
      color: @primary-color;
    }
    .remove:hover {
      color: @error-color;
    }
  }
}

.ant-upload-text {
  margin-top: 8px;
}
</style>
