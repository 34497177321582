export const RECORDING_METHOD = [
  {
    value: 'SUCCESS_RATE',
    label: '正确率（回合）'
  },
  {
    value: 'DURATION',
    label: '时长'
  },
  {
    value: 'NUMBER_SUCCESS',
    label: '频率'
  },
  {
    value: 'STEP_SUCCESS_RATE',
    label: '正确率（任务分析）'
  }
]

export const CLASS_BEGIN_METHOD = [
  {
    value: 'TIMING',
    label: '计时'
  },
  {
    value: 'COUNT',
    label: '计次'
  },
  {
    value: 'TIMING_COUNT',
    label: '计时计次'
  },
  {
    value: 'COUNTDOWN_COUNT',
    label: '固定时长计次'
  }
]

export const TRAINING_METHOD = [
  {
    value: 'NET',
    label: '自然教学NET'
  },
  {
    value: 'DTT',
    label: '回合式训练DTT'
  },
  {
    value: 'TA',
    label: '任务分析TA'
  },
  {
    value: 'DI',
    label: '直接教学DI'
  },
  {
    value: 'PT',
    label: '精准教学PT'
  }
]


export const SCHOOL_STATUS_STATUS = [
  {
    value: 'ADMISSION',
    label: '在园'
  },
  {
    value: 'WITHDRAWAL',
    label: '离园'
  }
]
export const COMMON_STATUS = [
  {
    value: 'UNAVAILABLE',
    label: '禁用'
  },
  {
    value: 'AVAILABLE',
    label: '启用'
  }
]
// 启用、禁用取反（操作按钮）
export const COMMON_STATUS_REVERSE = [
  {
    value: 'AVAILABLE',
    label: '禁用'
  },
  {
    value: 'UNAVAILABLE',
    label: '启用'
  }
]

export const FIELD_LEVEL = [
  {
    value: 'MAIN',
    label: '一级'
  },
  {
    value: 'SECONDARY',
    label: '二级'
  }
]
export const SUITABLE_AGE = [
  { value: 1, label: '1岁及以上' },
  { value: 2, label: '2岁及以上' },
  { value: 3, label: '3岁及以上' },
  { value: 4, label: '4岁及以上' },
  { value: 5, label: '5岁及以上' },
  { value: 6, label: '6岁及以上' },
  { value: 7, label: '7岁及以上' }
]
export const SKILL_LEVEL = [
  { value: 'LEVEL1', label: 'level1' },
  { value: 'LEVEL2', label: 'level2' },
  { value: 'LEVEL3', label: 'level3' }
]
export const SKILL_STATUS = [
  {
    value: 'CREATED',
    label: '待发布'
  },
  {
    value: 'PUBLISHED',
    label: '已发布'
  },
  {
    value: 'DISABLED',
    label: '禁用'
  }
]
export const GOODS_SKILL_STATUS = [
  {
    value: 'CREATED',
    label: '待上架'
  },
  {
    value: 'ON_SHELF',
    label: '上架'
  },
  {
    value: 'OFF_SHELF',
    label: '下架'
  }
]
export const SKILL_TYPE = [
  {
    value: 'NONE',
    label: '无'
  },
  {
    value: 'DETECTION',
    label: '探测'
  },
  {
    value: 'LANGUAGE',
    label: '语言'
  }
]
export const PERMISSION_COMMON_STATUS = [
  {
    label: '查看全部学员',
    value: 'WHOLE_STUDENT'
  },
  {
    label: '查看分配学员',
    value: 'DISTRIBUTION_STUDENT'
  }
]
export const REDEMTION_CODE_TYPE = [
  {
    value: 'SKILL',
    label: '技能'
  },
  {
    value: 'SERVICE',
    label: '服务'
  },
  {
    value: 'MEMBER',
    label: '会员'
  }
]
export const SERVICE_MANAGER_TYPE = [
  {
    value: 'SUPERVISION',
    label: '督导服务'
  },
  {
    value: 'INTERVIEW',
    label: '访谈服务'
  },
  {
    value: 'HOME_SCHOOL_TRAINING_PROGRAM',
    label: '家校训练计划'
  },
  {
    value: 'DATA_REPORT',
    label: '数据报告'
  },
  {
    value: 'FOCUS_PLAN',
    label: '焦点计划'
  },
  {
    value: 'VIDEO_CLASS',
    label: '录播课'
  },
  {
    value: 'BOOKS',
    label: '图书'
  }
]
export const PLAN_SKILL_STATUS = [
  {
    value: 'CREATED',
    label: '未发布'
  },
  {
    value: 'PUBLISHED',
    label: '训练中'
  },
  {
    value: 'COMPLETED',
    label: '已完成'
  }
]
export const REDEMTION_CODE_STATUS = [
  {
    value: 'NOT_USED',
    label: '未使用'
  },
  {
    value: 'USED',
    label: '已使用'
  },
  {
    value: 'EXPIRED',
    label: '已过期'
  }
]
export const ORDER_STATUS = [
  { value: '', label: '全部订单' },
  { value: 'TO_BE_PAY', label: '待付款' },
  { value: 'PAID', label: '已付款' },
  { value: 'CANCEL', label: '已取消' }
]
export const ORDER_PAYMENT_METHOD = [
  { value: 'ALIPAY', label: '支付宝' },
  { value: 'NULL', label: '未支付' },
  { value: 'WECHAT', label: '微信' },
  { value: 'REDEMPTIONCODE', label: '兑换码' }
]
export const ORDER_GOODS_TYPE = [
  { value: 'INDIVIDUAL', label: '单项服务' },
  { value: 'SKILL', label: '技能包' },
  { value: 'COMBINATION', label: '组合服务' }
]

export const GENDER_TYPE = [
  { value: 'MALE', label: '男' },
  { value: 'FEMALE', label: '女' }
]

export const EVALUATION_REPORT_STATUS = [
  {
    value: 'POSTED',
    label: '已发布'
  },
  {
    value: 'UNPOSTED',
    label: '待发布'
  },
  {
    value: 'UNGENERATED',
    label: '待生成'
  }
]

