<template>
  <a-upload name="file" :multiple="false" :showUploadList="false" :beforeUpload="action" :customRequest="() => {}">
    <div>
      <a-button type="round" :disabled="uploading">
        <a-icon :type="uploading ? 'loading' : 'upload'" />{{ uploadText }}
      </a-button>
    </div>
  </a-upload>
</template>
<script>
import * as uploadApi from '@/api/upload'
import { getFileExtention } from './upload'
export default {
  props: {
    accept: {
      type: Array,
      default() {
        return []
      }
    },
    limit: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      uploading: false,
      per: 0
    }
  },
  computed: {
    uploadText() {
      return this.uploading ? (this.per < 100 ? `正在上传 ${this.per}%` : '文件上传完毕，正在解析') : '上传文件'
    }
  },
  methods: {
    action(file) {
      // const gb = 1024 * 1024 * 1024
      if (this.limit > 0 && file.size > this.limit) {
        let deep = 0
        let { limit } = this
        while (limit > 1024 && deep < 3) {
          limit = parseInt(limit / 1024)
          deep++
        }
        const units = ['B', 'KB', 'MB', 'GB']
        const msg = `${file.name} 文件大于${limit}${units[deep]}`
        this.$message.error(msg)
        this.$emit('error', msg)
        return false
      }
      if (this.accept.length > 0 && !this.accept.includes(getFileExtention(file.name).toLowerCase())) {
        const msg = `请选择 ${this.accept.join('/')} 格式的文件`
        this.$message.error(msg)
        this.$emit('error', msg)
        return false
      }
      this.$emit('change')
      this.uploading = true
      this.per = 0
      const formData = new FormData()
      formData.append('file', file)
      return uploadApi
        .resource({
          formData,
          onUploadProgress: progressEvent => {
            const complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0
            this.per = complete
          }
        })
        .then(res => {
          this.$emit('success', res.data)
          this.$message.success(`${file.name} 文件上传成功`)
        })
        .catch(err => {
          console.log(err)
          this.$message.error(err.msg)
          this.$emit('error', err.msg)
        })
        .finally(() => {
          this.uploading = false
          this.per = 0
        })
    }
  }
}
</script>
