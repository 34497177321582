import moment from 'moment'
import store from '@/store'

export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

export function welcome() {
  const arr = ['休息一会儿吧', '准备吃什么呢?', '要不要打一把 DOTA', '我猜你可能累了']
  const index = Math.floor(Math.random() * arr.length)
  return arr[index]
}

/**
 * 触发 window.resize
 */
export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  event.eventType = 'message'
  window.dispatchEvent(event)
}

export function handleScrollHeader(callback) {
  let timer = 0

  let beforeScrollTop = window.pageYOffset
  callback = callback || function() {}
  window.addEventListener(
    'scroll',
    event => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        let direction = 'up'
        const afterScrollTop = window.pageYOffset
        const delta = afterScrollTop - beforeScrollTop
        if (delta === 0) {
          return false
        }
        direction = delta > 0 ? 'down' : 'up'
        callback(direction)
        beforeScrollTop = afterScrollTop
      }, 50)
    },
    false
  )
}

export function isIE() {
  const bw = window.navigator.userAgent
  const compare = s => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
export function removeLoadingAnimate(id = '', timeout = 1500) {
  if (id === '') {
    return
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id))
  }, timeout)
}

/* ---------------------- */
/**
 * 阿拉伯数字转中文数字,
 * 如果传入数字时则最多处理到21位，超过21位js会自动将数字表示成科学计数法，导致精度丢失和处理出错
 * 传入数字字符串则没有限制
 * @param {number|string} digit
 */
export function convertToChineseNumber(digit) {
  digit = typeof digit === 'number' ? String(digit) : digit
  const zh = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
  const unit = ['千', '百', '十', '']
  const quot = [
    '万',
    '亿',
    '兆',
    '京',
    '垓',
    '秭',
    '穰',
    '沟',
    '涧',
    '正',
    '载',
    '极',
    '恒河沙',
    '阿僧祗',
    '那由他',
    '不可思议',
    '无量',
    '大数'
  ]

  let breakLen = Math.ceil(digit.length / 4)
  let notBreakSegment = digit.length % 4 || 4
  let segment
  const zeroFlag = []
  const allZeroFlag = []
  let result = ''

  while (breakLen > 0) {
    if (!result) {
      // 第一次执行
      segment = digit.slice(0, notBreakSegment)
      const segmentLen = segment.length
      for (let i = 0; i < segmentLen; i++) {
        if (segment[i] !== 0) {
          if (zeroFlag.length > 0) {
            result += '零' + zh[segment[i]] + unit[4 - segmentLen + i]
            // 判断是否需要加上 quot 单位
            if (i === segmentLen - 1 && breakLen > 1) {
              result += quot[breakLen - 2]
            }
            zeroFlag.length = 0
          } else {
            result += zh[segment[i]] + unit[4 - segmentLen + i]
            if (i === segmentLen - 1 && breakLen > 1) {
              result += quot[breakLen - 2]
            }
          }
        } else {
          // 处理为 0 的情形
          if (segmentLen === 1) {
            result += zh[segment[i]]
            break
          }
          zeroFlag.push(segment[i])
          continue
        }
      }
    } else {
      segment = digit.slice(notBreakSegment, notBreakSegment + 4)
      notBreakSegment += 4

      for (let j = 0; j < segment.length; j++) {
        if (segment[j] !== 0) {
          if (zeroFlag.length > 0) {
            // 第一次执行zeroFlag长度不为0，说明上一个分区最后有0待处理
            if (j === 0) {
              result += quot[breakLen - 1] + zh[segment[j]] + unit[j]
            } else {
              result += '零' + zh[segment[j]] + unit[j]
            }
            zeroFlag.length = 0
          } else {
            result += zh[segment[j]] + unit[j]
          }
          // 判断是否需要加上 quot 单位
          if (j === segment.length - 1 && breakLen > 1) {
            result += quot[breakLen - 2]
          }
        } else {
          // 第一次执行如果zeroFlag长度不为0, 且上一划分不全为0
          if (j === 0 && zeroFlag.length > 0 && allZeroFlag.length === 0) {
            result += quot[breakLen - 1]
            zeroFlag.length = 0
            zeroFlag.push(segment[j])
          } else if (allZeroFlag.length > 0) {
            // 执行到最后
            if (breakLen === 1) {
              result += ''
            } else {
              zeroFlag.length = 0
            }
          } else {
            zeroFlag.push(segment[j])
          }

          if (j === segment.length - 1 && zeroFlag.length === 4 && breakLen !== 1) {
            // 如果执行到末尾
            if (breakLen === 1) {
              allZeroFlag.length = 0
              zeroFlag.length = 0
              result += quot[breakLen - 1]
            } else {
              allZeroFlag.push(segment[j])
            }
          }
          continue
        }
      }

      --breakLen
    }
    if (result.indexOf('十零') > -1) {
      result = result.replace('十零', '十')
    }
    if (result.indexOf('一十') > -1) {
      result = result.replace('一十', '十')
    }
    if (result.indexOf('二十') > -1) {
      result = result.replace('二十', '十')
    }
    if (result.indexOf('三十') > -1) {
      result = result.replace('三十', '十')
    }
    return result
  }
}
// 开始时间小于等于结束时间
export function checkDateIsBeforeEqual(start, end) {
  if (!start || !end) return false
  if (!moment(start).isBefore(end)) return true
  return false
}

/**
 * 从原始对象开始遍历，查找目标对象，如果有则覆盖原始对象中的值
 * 注：
 *   合并表单数据，后台返回的字段中可能存在多余的，这里只拷贝表单中存在的字段
 * @param  {Object} [origin={}] 原始对象
 * @param  {Object} [target={}] 目标对象
 * @return {Object}             原始对象
 */
export function merge(origin = {}, target = {}) {
  for (const p in origin) {
    if (target.hasOwnProperty(p)) {
      origin[p] = target[p]
    }
  }
  return origin
}

// 合并单元格
const mergeCellKey = (text, data, key, index) => {
  // 上一行该列数据是否一样
  if (index !== 0 && text === data[index - 1][key]) {
    return 0
  }
  let rowSpan = 1
  // 判断下一行是否相等
  for (let i = index + 1; i < data.length; i++) {
    if (text !== data[i][key]) {
      break
    }
    rowSpan++
  }
  return rowSpan
}

// 判断某个文件，是否存在服务器（视频）
const fileIsOnTheServer = url => {
  return new Promise((resolve, reject) => {
    var xmlhttp
    if (window.XMLHttpRequest) {
      xmlhttp = new XMLHttpRequest()
    } else {
      // eslint-disable-next-line no-undef
      xmlhttp = new ActiveXObject('Microsoft.XMLHTTP')
    }
    xmlhttp.open('HEAD', url, false)
    xmlhttp.send()
    if (xmlhttp.readyState === 4) {
      if (xmlhttp.status === 200) {
        resolve()
      }

      if (xmlhttp.status === 404) {
        reject()
      }
    }
  })
}

// 预览视频文件
const previewVideo = url => {
  const flag = ['origin', 'output'].every(item => {
    return url.indexOf(item) === -1
  })
  // origin和output都不存在
  if (flag) {
    var a = document.createElement('a') // 创建a标签
    a.setAttribute('href', url)
    a.setAttribute('target', '_blank')
    a.setAttribute('id', 'previewVideo')
    document.body.appendChild(a)
    a.click() // 执行当前对象
    // 移除
    setTimeout(() => {
      document.getElementById('previewVideo').remove()
    })
  } else {
    fileIsOnTheServer(url)
      .then(() => {
        // 'url存在'
        window.open(url, '_blank')
      })
      .catch(() => {
        // 'url不存在'
        const _url = url.replaceAll('output', 'origin')
        window.open(_url, '_blank')
      })
  }
}

// 下载二进制文件流（PDF）
export function downLoadBinaryFileStream({ data, type, name }) {
  const blob = new Blob([data], {
    type // 这里如果是其他格式的文件，把pdf改为所下载的文件格式就可以
  })
  const fileName = name
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const elink = document.createElement('a')
    elink.download = fileName
    elink.style.display = 'none'
    elink.href = URL.createObjectURL(blob)
    // console.log(blob, elink.href, 'href', elink)

    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL 对象
    document.body.removeChild(elink)
  } else {
    // IE10+下载
    navigator.msSaveBlob(blob, fileName)
  }
  // 关闭loading
  store.dispatch('changeSpinning', false)
}

// 秒转为时、分、秒
const formatSecondsToHourMinuteSecond= (value) => { 
const time = moment.duration(value, 'seconds')  // 得到一个对象，里面有对应的时分秒等时间对象值
  const hours = time.hours() 
  const minutes = time.minutes()
  const seconds = time.seconds()
  return moment({h:hours, m:minutes, s:seconds}).format('HH时mm分ss秒')
}

// 秒转为分、秒
const formatSecondsToMinuteSecond = (seconds) => { 
    let minute = '00'
    let second = '00'
    if (seconds < 60) {
      second = formatLessTen(seconds)
    } else {
      minute = formatLessTen(parseInt(seconds / 60))
      second = formatLessTen(parseInt(seconds % 60))
    }
    return [minute, second]
}

 // 格式化数字 往前补0
const formatLessTen = (number) => {
    return number < 10 ? `0${number}` : number
 }

export { mergeCellKey, fileIsOnTheServer, previewVideo, formatSecondsToHourMinuteSecond, formatSecondsToMinuteSecond, formatLessTen }
