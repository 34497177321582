<template>
  <pro-layout
    :siderWidth="176"
    :menus="menus"
    :collapsed="collapsed"
    :mediaQuery="query"
    :isMobile="isMobile"
    :handleMediaQuery="handleMediaQuery"
    :handleCollapse="handleCollapse"
    :i18nRender="i18nRender"
    v-bind="settings"
  >
    <!-- 1.0.0+ 版本 pro-layout 提供 API，
          我们推荐使用这种方式进行 LOGO 和 title 自定义
    -->
    <template v-slot:menuHeaderRender>
      <a-space style="line-height:normal" :size="5">
        <logo-svg style="width:40px;height:40px" />
        <a-space align="start" direction="vertical" :size="0" v-if="!collapsed">
          <h1 style="opacity:0.9;margin:0;letter-spacing:1px;">{{ title }}</h1>
          <span
            style="color:#ffffff;opacity:0.4;margin:0;letter-spacing:1px;font-size:8px"
          >孤独症综合评估系统</span>
        </a-space>
      </a-space>
    </template>
    <!-- 1.0.0+ 版本 pro-layout 提供 API,
          增加 Header 左侧内容区自定义
    -->
    <!-- <template v-slot:headerContentRender>
      <div>
        <a-tooltip title="刷新页面">
          <a-icon
            type="reload"
            style="font-size: 18px;cursor: pointer;"
            @click="() => { $message.info('只是一个DEMO') }"
          />
        </a-tooltip>
      </div>
    </template>-->

    <template v-slot:rightContentRender>
      <div class="header-multi-tab-wrap">
        <multi-tab ref="multi" v-if="multiTab"></multi-tab>
      </div>
      <right-content
        :top-menu="settings.layout === 'topmenu'"
        :is-mobile="isMobile"
        :theme="settings.theme"
      />
    </template>
    <!-- custom footer / 自定义Footer -->
    <template v-slot:footerRender>
      <!-- <global-footer /> -->
      <div></div>
    </template>
    <div class="default-bg-content" v-show="hasroute">
      <div class="title">嗨，很高兴见到你！</div>
      <div class="subtitle">WELCOME</div>
      <img src="~@/assets/bg_welcome_2.png" alt />
    </div>
    <router-view />
  </pro-layout>
</template>

<script>
import MultiTab from '@/components/MultiTab'
// import { asyncRouterMap } from '@/config/router.config.js'
import { i18nRender } from '@/locales'
import { mapState } from 'vuex'
import { CONTENT_WIDTH_TYPE, SIDEBAR_TYPE, TOGGLE_MOBILE_TYPE } from '@/store/mutation-types'

import defaultSettings from '@/config/defaultSettings'
import RightContent from '@/components/GlobalHeader/RightContent'
import GlobalFooter from '@/components/GlobalFooter'
import LogoSvg from '../assets/logo.svg?inline'

export default {
  name: 'BasicLayout',
  components: {
    MultiTab,
    RightContent,
    GlobalFooter,
    LogoSvg,
  },
  data() {
    return {
      // 是否显示欢迎图片
      hasroute: false,
      multiTab: defaultSettings.multiTab,

      // base
      menus: [],
      // 侧栏收起状态
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,

        hideHintAlert: false,
        hideCopyButton: false,
      },
      // 媒体查询
      query: {},

      // 是否手机模式
      isMobile: false,
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
    }),
    pages() {
      // console.log(this.$refs.multi ? this.$refs.multi.pages : '')
      return this.$refs.multi ? this.$refs.multi.pages.map((page) => page.name) : []
    },
  },
  created() {
    // const routes = asyncRouterMap.find(item => item.path === '/')
    const routes = this.mainMenu.find((item) => item.path === '/')
    this.menus = (routes && routes.children) || []
    if (this.$route.name === 'index') {
      this.hasroute = true
    }
    // 处理侧栏收起状态

    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })
  },
  mounted() {
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }
  },
  methods: {
    i18nRender,
    handleMediaQuery(val) {
      this.query = val
      if (this.isMobile && !val['screen-xs']) {
        this.isMobile = false
        return
      }
      if (!this.isMobile && val['screen-xs']) {
        this.isMobile = true
        this.collapsed = false
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
        // this.settings.fixSiderbar = false
      }
    },
    handleCollapse(val) {
      this.collapsed = val
    },
    handleSettingChange({ type, value }) {
      console.log('type', type, value)
      type && (this.settings[type] = value)
      switch (type) {
        case 'contentWidth':
          this.settings[type] = value
          break
        case 'layout':
          if (value === 'sidemenu') {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
          } else {
            this.settings.fixSiderbar = false
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed
          }
          break
      }
    },
  },
  watch: {
    $route(newroute) {
      // 开始上课-侧边栏收起
      if (newroute.name === 'homeSchoolClassesBegin') {
        this.collapsed = true
      }
      if (newroute.name !== 'index') {
        this.hasroute = false
      } else {
        this.hasroute = true
      }
    },
  },
}
</script>

<style lang="less">
@import './BasicLayout.less';

.default-bg-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 90vh;
  background-size: 1200px auto;
  background: #ffffff;
  font-family: PingFangSC, PingFang SC;
  .title {
    margin-top: 98px;
    font-weight: 500;
    font-size: 32px;
    color: #515458;
  }
  .subtitle {
    margin-bottom: 32px;
    font-weight: 500;
    font-size: 24px;
    color: rgba(109, 126, 169, 0.35);
  }
  img {
    width: 435px;
    height: 261px;
  }
}
</style>
