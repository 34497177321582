import Vue from 'vue'
import Router from 'vue-router'
import storage from 'store'
import { constantRouterMap } from '@/config/router.config'

//  router push callback
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

// 根据缓存中的登录页修改重定向
function getRedirectPath() {
  return storage.get('loginPath')
}
constantRouterMap[0].redirect = getRedirectPath()

const router = new Router({
  mode: 'history',
  routes: constantRouterMap // .concat(asyncRouterMap)
})
router.$addRoutes = params => {
  router.matcher = new Router({ mode: 'history', routes: constantRouterMap }).matcher
  router.addRoutes(params)
}
export default router
