import Vue from 'vue'
import {
  COMMON_STATUS,
  FIELD_LEVEL,
  SKILL_TYPE,
  SKILL_LEVEL,
  SKILL_STATUS,
  RECORDING_METHOD,
  TRAINING_METHOD,
  SUITABLE_AGE,
  GOODS_SKILL_STATUS,
  REDEMTION_CODE_TYPE,
  SERVICE_MANAGER_TYPE,
  ORDER_STATUS,
  ORDER_PAYMENT_METHOD,
  ORDER_GOODS_TYPE,
  REDEMTION_CODE_STATUS,
  COMMON_STATUS_REVERSE,
  CLASS_BEGIN_METHOD,
  EVALUATION_REPORT_STATUS,
  GENDER_TYPE,
  SCHOOL_STATUS_STATUS
} from '@/store/dictionary'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

Vue.filter('NumberFormat', function(value) {
  if (!value) {
    return '0'
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') // 将整数部分逢三一断
  return intPartFormat
})

Vue.filter('moment', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return dataStr ? moment(dataStr).format(pattern) : ''
})

Vue.filter('GenderText', function(val) {
  const { label } = GENDER_TYPE.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('schoolStatusText', function(val) {
  const { label } = SCHOOL_STATUS_STATUS.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('StatusText', function(val) {
  const { label } = COMMON_STATUS.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('StatusTextReverse', function(val) {
  const { label } = COMMON_STATUS_REVERSE.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('FieldLevelText', function(val) {
  const { label } = FIELD_LEVEL.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('SkillTypeStyle', function(val) {
  return {
    DETECTION: {
      color: '#2795CC',
      background: '#E1F5FF'
    },
    LANGUAGE: {
      color: '#EF5C26',
      background: '#FFE9E1'
    }
  }[val]
})

Vue.filter('SkillTypeText', function(val) {
  const { label } = SKILL_TYPE.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('SkillLevelText', function(val) {
  const { label } = SKILL_LEVEL.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('SkillStatusText', function(val) {
  const { label } = SKILL_STATUS.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('SkillNextStatusText', function(val) {
  return {
    CREATED: '发布',
    PUBLISHED: '禁用',
    DISABLED: '发布'
  }[val]
})

Vue.filter('SkillStatusBadge', function(val) {
  return {
    CREATED: 'default',
    PUBLISHED: 'success',
    DISABLED: 'error'
  }[val]
})

Vue.filter('SkillStatusClass', function(val) {
  return {
    CREATED: 'warning-btn',
    PUBLISHED: 'success-btn',
    DISABLED: 'error-btn'
  }[val]
})

Vue.filter('RecordingMethodText', function(val) {
  const { label } = RECORDING_METHOD.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('ClassBeginMethodText', function(val) {
  const { label } = CLASS_BEGIN_METHOD.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('TrainingMethodText', function(val) {
  const { label } = TRAINING_METHOD.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('SuitableAgeText', function(val) {
  const { label } = SUITABLE_AGE.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('GoodsSkillStatusText', function(val) {
  const { label } = GOODS_SKILL_STATUS.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('RedemptionCodeTypeText', function(val) {
  const { label } = REDEMTION_CODE_TYPE.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('RedemptionStatusText', function(val) {
  const { label } = REDEMTION_CODE_STATUS.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('ServiceManagerTypeText', function(val) {
  const { label } = SERVICE_MANAGER_TYPE.filter(v => v.value === val)[0] || {}
  return label || '-'
})

Vue.filter('OrderStatusText', function(val) {
  return ORDER_STATUS.some(v => v.value === val) ? ORDER_STATUS.filter(v => v.value === val)[0].label : '-'
})
Vue.filter('OrderPaymentMethod', function(val) {
  return ORDER_PAYMENT_METHOD.some(v => v.value === val)
    ? ORDER_PAYMENT_METHOD.filter(v => v.value === val)[0].label
    : '-'
})
Vue.filter('OrderGoodsType', function(val) {
  return ORDER_GOODS_TYPE.some(v => v.value === val) ? ORDER_GOODS_TYPE.filter(v => v.value === val)[0].label : '-'
})

Vue.filter('EvaluationReportStatusText', function(val) {
  return EVALUATION_REPORT_STATUS.some(v => v.value === val) ? EVALUATION_REPORT_STATUS.filter(v => v.value === val)[0].label : '-'
})

Vue.filter('BasicInformationPhone', function(val) {
  console.log('BasicInformationPhone val::',val)
  if (!val) return '--'
  return val.substring(0, 3) + '****' + val.substring(7,11)
})
