<template>
  <div>
    <a-dropdown v-if="currentUser && currentUser.name" placement="bottomRight">
      <span class="ant-pro-account-avatar">
        <a-avatar
          size="small"
          src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
          class="antd-pro-global-header-index-avatar"
        />
        <span>{{ currentUser.name }}</span>
      </span>
      <template v-slot:overlay>
        <a-menu class="ant-pro-drop-down menu" :selected-keys="[]">
          <!-- <a-menu-item v-if="menu" key="center" @click="handleToCenter">
          <a-icon type="user" />
          {{ $t('menu.account.center') }}
          </a-menu-item>-->
          <!-- 修改密码 -->
          <a-menu-item key="changepassword" @click="handleToPassword">
            <a-icon type="setting" />
            {{ $t('menu.account.changepassword') }}
          </a-menu-item>
          <a-menu-item key="logout" @click="handleLogout">
            <a-icon type="logout" />
            {{ $t('menu.account.logout') }}
          </a-menu-item>
        </a-menu>
      </template>
    </a-dropdown>
    <span v-else>
      <a-spin size="small" :style="{ marginLeft: 8, marginRight: 8 }" />
    </span>
    <a-modal
      v-model="visible"
      title="修改密码"
      width="716px"
      :body-style="{ padding: '20px, 24px', backgroundColor: '#f6f7fc' }"
      :destroyOnClose="true"
      :ok-button-props="{
        props: { shape: 'round', loading: buttonLoading },
        domProps: {
          innerHTML: currentStep === 0 ? '下一步' : '确定'
        }
      }"
      :cancel-button-props="{ props: { shape: 'round' } }"
      @ok="okHandle"
      @cancel="cancelHandle"
    >
      <a-form-model ref="form" layout="horizontal" :model="form" v-bind="formItemLayout">
        <div v-if="currentStep === 0">
          <a-form-model-item label="请输入旧密码:">
            <a-input-password
              id="oldpassword"
              v-model="form.oldpassword"
              @change="change"
              placeholder="请输入旧密码"
            ></a-input-password>
          </a-form-model-item>
        </div>
        <div v-if="currentStep === 1">
          <a-form-model-item label="请输入新密码:">
            <a-input-password
              id="newpassword"
              v-model="form.newpassword"
              placeholder="6-12位字符(字母、数字、符号)，区分大小写"
              @change="checkValue($event, 'newpassword')"
            ></a-input-password>
          </a-form-model-item>
          <a-form-model-item label="再次确认:">
            <a-input-password
              id="checkpassword"
              v-model="form.checkpassword"
              placeholder="再次确认"
              @change="checkValue($event, 'checkpassword')"
            ></a-input-password>
          </a-form-model-item>
        </div>
        <span style="marginLeft: 18%; color: #f5222d" v-show="checkPass">{{ passText }}</span>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import { verificationPassword, changePassword } from '@/api/login'
import storage from 'store'

export default {
  name: 'AvatarDropdown',
  props: {
    currentUser: {
      type: Object,
      default: () => null,
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      formItemLayout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      },
      visible: false,
      currentStep: 0, // 当前处于的步骤，0是验证旧密码，1是设置新密码
      checkPass: false, // 旧密码是否正确
      passText: '', // 提示文字
      buttonLoading: false,
      form: {
        oldpassword: '',
        newpassword: '',
        checkpassword: '',
      },
    }
  },
  methods: {
    handleToCenter() {
      this.$router.push({ path: '/account/center' })
    },
    handleToPassword() {
      this.visible = true
    },
    change() {
      this.checkPass = false
      this.passText = ''
    },
    okHandle() {
      const {
        currentStep,
        form: { oldpassword, newpassword, checkpassword },
      } = this

      if (currentStep === 0) {
        if (!oldpassword) {
          this.checkPass = true
          this.passText = '请输入旧密码'
        } else {
          this.buttonLoading = true
          this.checkPass = false
          this.passText = ''
          verificationPassword({ oldpassword })
            .then((res) => {
              if (res.data) {
                this.currentStep = 1
              } else {
                this.checkPass = true
                this.passText = '您输入的旧密码不正确'
                this.form.oldpassword = ''
              }
            })
            .finally(() => {
              this.buttonLoading = false
            })
        }
      } else if (currentStep === 1) {
        if (!newpassword) {
          this.checkPass = true
          this.passText = '请输入新密码'
        } else if (newpassword !== checkpassword) {
          this.checkPass = true
          this.passText = '两次密码不一致'
        } else if (newpassword === oldpassword) {
          this.checkPass = true
          this.passText = '新密码与旧密码不能相同'
        } else if (newpassword.length <= 5 || newpassword.length >= 13) {
          this.checkPass = true
          this.passText = '密码长度为6-12位'
        } else {
          this.buttonLoading = true
          this.checkPass = false
          this.passText = ''
          changePassword({ newpassword })
            .then((res) => {
              this.visible = false
              if (res.code === 0) {
                this.$notification.success({
                  message: '提示',
                  description: '修改密码成功',
                })
              } else {
                this.$notification.error({
                  message: '提示',
                  description: '修改密码失败',
                })
              }
            })
            .finally(() => {
              this.cancelHandle()
            })
        }
      }
    },
    cancelHandle() {
      this.visible = false
      this.checkPass = false
      this.passText = ''
      this.buttonLoading = false
      this.currentStep = 0
      this.form.checkpassword = ''
      this.form.newpassword = ''
      this.form.oldpassword = ''
    },
    checkValue(e, name) {
      const delspace = e.target.value.replace(/[\s*]/g, '')
      const newValue = delspace.replace(/[\u4E00-\u9FA5]/g, '')
      this.form[name] = newValue
    },
    handleLogout(e) {
      Modal.confirm({
        title: this.$t('layouts.usermenu.dialog.title'),
        content: this.$t('layouts.usermenu.dialog.content'),
        onOk: () => {
          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1500)
          // }).catch(() => console.log('Oops errors!'))
          return this.$store.dispatch('logout').then(() => {
            const loginPath = storage.get('loginPath')
            if (loginPath === '/user/login') {
              this.$router.push({ name: 'login' })
            } else {
              this.$router.push({ name: 'login/bjesr' })
            }
          })
        },
        onCancel() {},
      })
    },
  },
}
</script>

<style lang="less" scoped>
.ant-pro-drop-down {
  /deep/ .action {
    margin-right: 8px;
  }
  /deep/ .ant-dropdown-menu-item {
    min-width: 160px;
  }
}
</style>
