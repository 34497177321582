<template>
  <a-select :value="value" @change="e => $emit('input', e)" v-bind="$attrs" v-on="$listeners">
    <a-icon slot="suffixIcon" type="caret-down" />
    <slot></slot>
  </a-select>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number, Array],
      default: undefined
    }
  }
}
</script>

<style lang="less" scoped></style>
