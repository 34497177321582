import storage from 'store'
// eslint-disable-next-line
import { login, refreshToken, logout } from '@/api/login'
import { getInfo as getUserInfo } from '@/api/user'
import { ACCESS_TOKEN, REFRESH_TOKEN } from '@/store/mutation-types'
// eslint-disable-next-line
import { welcome } from '@/utils/util'

const user = {
  state: {
    spinning: false, // loading 标记
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {},
    isRefresh: false, // 是否在refreshtoken
    unlimitedNumber: 9999999, // 无限制的数量
    isSchoolStatus: false, // 学生-在园状态【true离园  false在园】
    loginPath: '', // 登录路径
  },
  getters: {
    getSpinning: state => state.spinning,
    getUserInfo: state => state.info,
    getUserMechanismLevel: state => state.info.mechanismLevel === 'SECONDARY', // 用户是否二级机构
    getUserMechanismIsPartyA: state => state.info.mechanismId === 20210308476 || state.info.mechanismId === 1, // 用户机构id是否“北大医疗脑健康” 、“超级机构”
    getSchoolStatus: state => state.isSchoolStatus,  // 学生-在园状态【true离园  false在园】
    getLoginPath: state => state.loginPath,
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_SPINNING: (state, spinning) => {
      state.spinning = spinning
    },
    SET_isRefresh: (state, isRefresh) => {
      state.isRefresh = isRefresh
    },
    // 学生-在园状态【true离园  false在园】
    SET_SCHOOL_STATUS: (state, flag) => {
      state.isSchoolStatus = flag
    },
    SET_LOGIN_PATH: (state, path) => {
      state.loginPath = path
    }
  },

  actions: {
    // 学生-在园状态【true离园  false在园】
    judgeSchoolStatus({ commit }, params) {
      const { schoolStatus } = JSON.parse(params)
      commit('SET_SCHOOL_STATUS', schoolStatus === 'WITHDRAWAL')
    },
    //  设置loading
    changeSpinning({ commit }, params) {
      commit('SET_SPINNING', params)
    },

    // 登录
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then(response => {
            resolve(response)
            const { token_type, access_token, refresh_token } = response.data
            const token = token_type + ' ' + access_token
            storage.set(ACCESS_TOKEN, token)
            storage.set(REFRESH_TOKEN, refresh_token, 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', token)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 重新登录
    refreshToken({ state, commit }, payload) {
      return new Promise((resolve, reject) => {
        if (!state.isRefresh) {
          commit('SET_isRefresh', true)
          refreshToken(payload)
            .then(response => {
              const { token_type, access_token, refresh_token } = response.data
              const token = token_type + ' ' + access_token
              storage.set(ACCESS_TOKEN, token)
              storage.set(REFRESH_TOKEN, refresh_token, 7 * 24 * 60 * 60 * 1000)
              commit('SET_TOKEN', token)
              resolve()
            })
            .catch(error => {
              reject(error)
            })
            .finally(() => {
              commit('SET_isRefresh', false)
            })
        }
      })
    },

    // 获取用户信息
    getInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getUserInfo()
          .then(response => {
            const result = response.data

            if (result.menuSet && result.menuSet.length > 0) {
              commit('SET_ROLES', result.menuSet)
              commit('SET_INFO', result)
            } else {
              reject(new Error('getInfo: roles must be a non-null array !'))
            }

            commit('SET_NAME', { name: result.username, welcome: welcome() })
            commit('SET_AVATAR', result.avatar)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    // 登出
    logout({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            storage.remove(ACCESS_TOKEN)
            storage.remove(REFRESH_TOKEN)
            resolve()
          })
          .catch(() => {
            reject()
          })
          .finally(() => { })
      })
    }
  }
}

export default user
