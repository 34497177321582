import request from '@/utils/request'

const uploadApi = {
  // aliSignature: '/resources/config',
  aliSignature: '/login/oss/token',
  zip: '/resources/zip',
  resource: '/resources/file'
}

export function aliSignature() {
  return request({
    url: uploadApi.aliSignature,
    method: 'get'
  })
}

export function zip({ formData, onUploadProgress }) {
  return request({
    url: uploadApi.zip,
    method: 'post',
    // timeout: 120000,
    onUploadProgress,
    data: formData
  })
}

export function resource({ formData, onUploadProgress }) {
  return request({
    url: uploadApi.resource,
    method: 'post',
    // timeout: 120000,
    onUploadProgress,
    data: formData
  })
}
