<template>
  <a-upload name="file" :multiple="false" :showUploadList="false" :beforeUpload="action" :customRequest="() => {}">
    <div>
      <a-button type="round" :disabled="uploading">
        <a-icon :type="uploading ? 'loading' : 'upload'" />{{ uploadText }}
      </a-button>
      <a-tooltip placement="right" style="margin-left:16px">
        <template slot="title">
          <span>上传文件仅为zip格式，文件大小不能超过1GB，音频文件为MP3格式，视频文件为MP4，图片文件为PNG</span>
        </template>
        <a-icon type="exclamation-circle" theme="filled" />
      </a-tooltip>
    </div>
  </a-upload>
</template>
<script>
import * as uploadApi from '@/api/upload'
export default {
  data() {
    return {
      uploading: false,
      per: 0
    }
  },
  computed: {
    uploadText() {
      return this.uploading ? (this.per < 100 ? `正在上传 ${this.per}%` : '文件上传完毕，正在解析') : '上传文件'
    }
  },
  methods: {
    action(file) {
      const gb = 1024 * 1024 * 1024
      if (file.size > gb) {
        const msg = `${file.name} 文件大于1GB`
        this.$message.error(msg)
        this.$emit('error', msg)
        return false
      }
      if (!file.name.toLowerCase().endsWith('.zip')) {
        const msg = `请选择 zip 格式的文件`
        this.$message.error(msg)
        this.$emit('error', msg)
        return false
      }
      this.$emit('change')
      this.uploading = true
      this.per = 0
      const formData = new FormData()
      formData.append('file', file)
      return uploadApi
        .zip({
          formData,
          onUploadProgress: progressEvent => {
            const complete = ((progressEvent.loaded / progressEvent.total) * 100) | 0
            this.per = complete
          }
        })
        .then(res => {
          this.$emit('success', res.data)
          this.$message.success(`${file.name} 文件上传成功`)
        })
        .catch(err => {
          console.log(err)
          this.$message.error(err.msg)
          this.$emit('error', err.msg)
        })
        .finally(() => {
          this.uploading = false
          this.per = 0
        })
    }
  }
}
</script>
